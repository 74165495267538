import { Controller } from "stimulus"
import { MarkerClusterer } from "@googlemaps/markerclusterer";

let markers
let markersFlag = true
let map
let icon
let icon2
let infoWindow;
let geocoder

export default class extends Controller {

  static targets = ["map"]
  static values = { icon: String, icon2: String }
  
  connect() {
    markers = []
    markersFlag = true;
    infoWindow = new google.maps.InfoWindow();
    icon = {
      url: this.iconValue,
      scaledSize: new google.maps.Size(48, 48),
    };
    icon2 = {
      url: this.icon2Value,
      size: new google.maps.Size(80, 80),
      scaledSize: new google.maps.Size(80, 80),
      anchor: new google.maps.Point(40, 40)
    };
    if (typeof (google) != "undefined") {
      this.homeMap()
    }
  }
  

  homeMap() {
    
    map = new google.maps.Map(this.mapTarget, {
      center: { lat: 40.417, lng: -3.703 },
      zoom: 10,
    });
    geocoder = new google.maps.Geocoder();
    let pos = {}

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          map.setCenter(pos);
          map.setZoom(15);
          geocoder.geocode({ location: pos }).then((response) => {
            if (response) {
              const event = new Event("change");
              const result = response.results[response.results.length - 3]
              let element = document.getElementById("community_selector_min") || document.getElementById("community_selector");
              if (element) {
                element.classList.add('w-32')
                element.value = result.address_components[1].long_name
                element.dispatchEvent(event);
              }
              setTimeout(() => {
                element = document.getElementById("province_selector")
                if (element) {
                  element.classList.add('w-32')
                  element.value = result.address_components[0].long_name
                  element.dispatchEvent(event);
                }
              }, 2000);
              setTimeout(() => {
                element = document.getElementById("municipality_selector")
                if (element) element.classList.add('w-32')
                document.getElementById("spinner_min").classList.add('hidden')
              }, 4000);
            }
          });
        },
        () => {
          handleLocationError(true, infoWindow, map.getCenter());
        }
      );
    } else {
      console.log("El buscador no permite la geolocalización");
      handleLocationError(false, infoWindow, map.getCenter());
    }

    
    function handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(
        browserHasGeolocation
          ? "Error: El servicio de geolocalización ha fallado."
          : "Error: El buscador no soporta el servicio de geolocalización."
      );
      infoWindow.open(map);
    }

    this.setMarkers(map);

    /*map.addListener("bounds_changed", () => {
      if (markersFlag) {
        markersFlag = false
        window.setTimeout(() => {
          this.setMarkers(map)
          markersFlag = true
        }, 3000);
      }
    });*/

    window.initMap = this.homeMap;
  }

  setMarkers() {
    let url = `/properties/markers`;
    this.clearMarkers();

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        for (let i = 0; i < data.length; i++) {
          let pos = {
            lat: parseFloat(data[i].property.latitude),
            lng: parseFloat(data[i].property.longitude),
          };
          const exact_address = data[i].property.show
          let marker = new google.maps.Marker({
            position: pos,
            map: map,
            icon: exact_address ? icon : icon2,
            opacity: exact_address ? 1 : 0.7
          });
          markers.push(marker);

          this.setInfoWindow(marker, data, i)

        }
        let cluster = new MarkerClusterer({ map, markers });
      });
  }

  setInfoWindow(marker, data, i) {
    let area = data[i].property.parcel != undefined && data[i].property.parcel !== 0 ? data[i].property.parcel : data[i].property.builded;
    let rent_price = data[i].property.rent_price == '0 €' ? "" : `<p class="font-bold">${data[i].property.rent_price}/mes</p>`
    let sale_price = data[i].property.sale_price == '0 €' ? "" : `<p class="font-bold">${data[i].property.sale_price}</p>`
    let bedrooms = data[i].property.bedrooms == undefined ? "" : `<p>${data[i].property.bedrooms} habs.</p>`
    let disponibility
    if (sale_price == "") {
      disponibility = "Alquiler"
    } else if (rent_price == "") {
      disponibility = "Compra"
    } else {
      disponibility = "Compra y alquiler"
    }

    let locale = document.documentElement.lang;
    let title = data[i].property.title.replace(/\s+(?=\S)/g, '-');
    title = title.replace(/\.$/, '');
    let url = encodeURI(`/${locale}/property/${data[i].property.id}/${title}`)
    let infoWindowContent =
      '<div class="w-full sm:w-[360px] grid grid-cols-2 gap-5 px-2 pb-4">' +
      '<div class="relative">' +
      `<a href="${url}" class="pb-1">` +
      `<img class="w-full h-[120px] object-contain" src="${data[i].property.image}" alt="${data[i].property.title}" >` +
      "</a>" +
      "<div class='absolute bg-bee-brown text-white bottom-0 sm:left-2 px-2 sm:px-4 py-1'>" +
      rent_price +
      sale_price +
      "</div> " +
      "</div> " +
      "<div class='flex flex-col gap-2'>" +
      `<p class="text-bee-brown font-semibold">${data[i].property.subtype}</p>` +
      `<p>${data[i].property.municipality}</p>` +
      '<div class="flex gap-5 border-b border-gray-200 pb-2">' +
      bedrooms +
      `<p>${area} m²</p>` +
      "</div>" +
      `<p>Disponibilidad: ${disponibility}</p>` +
      "</div>" +
      "</div>";
    
    google.maps.event.addListener(marker, "click", function () {
      infoWindow.setContent(infoWindowContent);
      infoWindow.open(map, marker);
    });
  }

  clearMarkers() {
    for (let i = 0; i < markers.length; i++ ) {
      markers[i].setMap(null);
    }
    markers.length = 0;
  }

  setMunicipality() {
    let community = document.getElementById("community_selector_min") || document.getElementById("community_selector");
    let province = document.getElementById("province_selector")
    let municipality = document.getElementById("municipality_selector")
    if (municipality) {
      const place = `${community.value}, ${province.value}, ${municipality.value}, España`
      geocoder.geocode({ address: place }, function(results, status) {
        if (status === 'OK') {
          map.setCenter(results[0].geometry.location);
          map.setZoom(15);
        } else {
          alert('La geocodificación inversa ha fallado debido a: ' + status);
        }
      });
    }
  }

}